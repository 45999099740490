import React from "react"
import { Link } from "gatsby"
import { pages, externalLinks } from "../../constants"
import { VPIcon } from "../atoms/VPIcon"
import { useIsMobile } from "../hooks/useDimensions"
import { footerTextGrey } from "../../assets/colors"
import {
  LinkedInSimpleIcon,
  // FacebookIcon,
  // InstagramIcon,
} from "../atoms/SocialIcons"

const FooterBox: React.FC<{ horizontal?: boolean }> = ({
  children,
  horizontal,
}) => {
  return (
    <div
      className={`pb-6 sm:border-l sm:pl-6 sm:pb-0 ${horizontal && "flex"}`}
      style={{ borderLeftColor: footerTextGrey }}
    >
      {children}
    </div>
  )
}

const FooterLink: React.FC<{ to: string; text: string }> = ({ to, text }) => (
  <Link to={to}>
    <p
      className="mb-2 text-sm tracking-wide uppercase"
      style={{ color: footerTextGrey }}
    >
      {text}
    </p>
  </Link>
)

const ExternalLink: React.FC<{
  href: string
  content: string | JSX.Element
}> = ({ href, content }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <p
      className="mb-2 mr-2 text-sm tracking-wide uppercase"
      style={{ color: footerTextGrey }}
    >
      {content}
    </p>
  </a>
)

const PageLinks = () => (
  <FooterBox>
    <FooterLink to={pages.jobSeekers} text={"Job Seekers"}></FooterLink>
    <FooterLink to={pages.hiringManagers} text="Hiring Managers"></FooterLink>
    <FooterLink to={pages.aboutUs} text="About Us"></FooterLink>
    <FooterLink to={pages.workForUs} text="Work For Us"></FooterLink>
    <FooterLink to={pages.privacy} text={"Privacy Policy"}></FooterLink>
  </FooterBox>
)

const ExternalLinks = () => (
  <FooterBox horizontal>
    <ExternalLink
      href={externalLinks.linkedIn}
      content={<LinkedInSimpleIcon width={30} color={footerTextGrey} />}
    ></ExternalLink>
    {/* <ExternalLink
      href={externalLinks.facebook}
      content={<FacebookIcon width={30} color={footerTextGrey} />}
    ></ExternalLink>
    <ExternalLink
      href={externalLinks.instagram}
      content={<InstagramIcon width={30} color={footerTextGrey} />}
    ></ExternalLink> */}
  </FooterBox>
)

interface Address {
  mobile?: boolean
}

const AddressTokyo: React.FC<Address> = ({ mobile }) => {
  const mobileAddress = (
    <>
      <p className="mb-2">
        Roppongi Hills North Tower 3F, 6-2-31 Roppongi, Minato-ku, Tokyo,
        106-0032 Japan{" "}
        <a
          className="px-1 font-sans text-xs font-bold transition duration-300 ease-in-out hover:text-gray-100"
          href={externalLinks.locationMap.tokyo}
          target="_blank"
          rel="noreferrer noopener"
        >
          MAP
        </a>
      </p>
    </>
  )

  const desktopAddress = (
    <>
      <p className="mb-2">Roppongi Hills North Tower 3F</p>
      <p className="mb-2">6-2-31 Roppongi, Minato-ku,</p>
      <div className="flex items-center mb-2">
        <p className="whitespace-no-wrap">Tokyo, 106-0032 Japan</p>

        <a
          className="px-2 font-sans text-xs font-bold transition duration-300 ease-in-out hover:text-gray-100"
          href={externalLinks.locationMap.tokyo}
          target="_blank"
          rel="noreferrer noopener"
        >
          MAP
        </a>
      </div>
    </>
  )
  return (
    <FooterBox>
      <div className="text-sm" style={{ color: footerTextGrey }}>
        <p className="mb-2 font-bold">VantagePoint K.K.</p>
        <div className="text-xs sm:text-sm">
          {mobile ? mobileAddress : desktopAddress}

          <div className="flex flex-row sm:flex-col">
            <p className="mb-2">T: +81 3 5413 4433</p>
            <p className="ml-10 sm:ml-0">E: info@vpointcareers.com</p>
          </div>
        </div>
      </div>
    </FooterBox>
  )
}

const AddressUSA: React.FC<Address> = ({ mobile }) => {
  const mobileAddress = (
    <>
      <p className="mb-2">
        101 S Tryon St, Suite 2700, Charlotte, NC 28280, USA{" "}
        <a
          className="px-1 font-sans text-xs font-bold transition duration-300 ease-in-out hover:text-gray-100"
          style={{ borderColor: footerTextGrey, transform: "scale(0.75)" }}
          href={externalLinks.locationMap.charlotte}
          target="_blank"
          rel="noreferrer noopener"
        >
          MAP
        </a>
      </p>
    </>
  )

  const desktopAddress = (
    <>
      <p className="mb-2">101 S Tryon St, Suite 2700,</p>
      <p className="mb-2">Charlotte, NC 28280,</p>
      <div className="flex items-center mb-2">
        <p>USA</p>

        <a
          className="px-2 font-sans text-xs font-bold transition duration-300 ease-in-out hover:text-gray-100"
          // style={{ borderColor: footerTextGrey, transform: "scale(0.75)" }}
          href={externalLinks.locationMap.charlotte}
          target="_blank"
          rel="noreferrer noopener"
        >
          MAP
        </a>
      </div>
    </>
  )
  return (
    <FooterBox>
      <div className="text-sm" style={{ color: footerTextGrey }}>
        <p className="mb-2 font-bold">VantagePoint Executive Search US (USA)</p>
        <div className="text-xs sm:text-sm">
          {mobile ? mobileAddress : desktopAddress}

          <div className="flex flex-row sm:flex-col">
            {/* <p className="mb-2">T: +000 0000 0000</p> */}
            <p className="sm:mb-2 sm:ml-0">E: info@vpointcareers.com</p>
          </div>
          {/* <p className="mb-2 text-xs">Employment Agency Licence: 60168</p> */}
        </div>
      </div>
    </FooterBox>
  )
}
const Footer = () => {
  const isMobile = useIsMobile()

  const mobile = (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridColumnGap: "2rem",
      }}
    >
      <PageLinks />
      <ExternalLinks />
      <AddressTokyo mobile={isMobile} />
      <AddressUSA mobile={isMobile} />
    </div>
  )

  const desktop = (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        height: 200,
      }}
    >
      <PageLinks />
      <ExternalLinks />
      <AddressTokyo />
      <AddressUSA />
    </div>
  )

  return (
    <div
      className="flex flex-col justify-between px-6 py-16 bg-vp-gray xs:px-10 xs:py-16 sm:p-16"
      style={{ minHeight: 490 }}
    >
      {isMobile ? mobile : desktop}
      <div className="flex flex-col items-center pt-6 mx-auto">
        <VPIcon width={60} />
        <p className="mt-6 text-xs" style={{ color: footerTextGrey }}>
          Copyright © VantagePoint All Rights Reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer
