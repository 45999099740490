/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import "./layout.css"
import Footer from "./Footer"

import { PageProp } from "../pages"
import { RemarkCreatorPlugin } from "gatsby-tinacms-remark"
import slugify from "slugify"
import { withPlugin } from "tinacms"
import { stripTrailingSlash } from "../utils"

const Layout: React.FC<PageProp> = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        path={location && stripTrailingSlash(location.pathname)}
      />
      <div className="m-0 overflow-hidden">
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

/**
 * This a is `create-content` plugin. It describes
 * a form for creating blog posts as as markdown files.
 * Registering this plugin reveals the `+` button in
 * the sidebar.
 */
const CreatePostPlugin = new RemarkCreatorPlugin({
  /**
   * Clicking the `+` button reveals a lost of content
   * types that can be created. This field sets the
   * label in that list.
   */
  label: "New Job Post",
  /**
   * Clicking the `Post` in the `+` list opens a modal.
   * This modal contains a form with these fields.
   */
  fields: [
    {
      name: "title",
      component: "text",
      label: "Job Post Title",
      required: true,
    },
    {
      name: "consultant",
      component: "text",
      label: "Consultant in Charge",
    },
  ],
  /**
   * Tina needs to know where the new markdown file is
   * going to live. This `filename` function generates
   * that path from the form's values. This function is required.
   */
  filename(form) {
    let slug = slugify(
      form.title.toLowerCase() +
        "_" +
        new Date().toDateString() +
        "_" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        "-" +
        new Date().getSeconds()
    )

    return `content/positions/${btoa(slug)}/index.md`
  },
  /**
   * We can optionally generate default frontmatter for the
   * new post. This can be a combination of form data and
   * programmatically generated.
   */
  frontmatter(form) {
    return {
      title: form.title,
      date: new Date(),
      description:
        "Short presentation, we pride ourselves on being one of the few talent acquisition firms with decade long retainer partnership with top multinationals.",
      consultant: form.consultant || "",
    }
  },
  /**
   * Finally, we can generate a default body. Right now
   * we don't need to do that.
   */
  body(form) {
    return ""
  },
})

const CreateConsultantPlugin = new RemarkCreatorPlugin({
  /**
   * Clicking the `+` button reveals a lost of content
   * types that can be created. This field sets the
   * label in that list.
   */
  label: "New Consultant",
  /**
   * Clicking the `Post` in the `+` list opens a modal.
   * This modal contains a form with these fields.
   */
  fields: [
    {
      name: "consultant",
      component: "text",
      label: "Name",
      required: true,
    },
    {
      name: "date",
      component: "date",
      label: "Date Joined",
    },
    {
      name: "position",
      component: "text",
      label: "Position",
      required: true,
    },
    {
      name: "location",
      component: "text",
      label: "Location",
      description: "Office Location",
      defaultValue: "Tokyo",
    },
  ],
  /**
   * Tina needs to know where the new markdown file is
   * going to live. This `filename` function generates
   * that path from the form's values. This function is required.
   */
  filename(form) {
    let slug = slugify(
      form.consultant.toLowerCase() +
        "_" +
        new Date().toDateString() +
        "_" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        "-" +
        new Date().getSeconds()
    )

    return `content/consultants/${btoa(slug)}/index.md`
  },
  /**
   * We can optionally generate default frontmatter for the
   * new post. This can be a combination of form data and
   * programmatically generated.
   */
  frontmatter(form) {
    return {
      date: form.date || new Date(),
      consultant: form.consultant,
      position: form.position,
      location: form.location,
    }
  },
  /**
   * Finally, we can generate a default body. Right now
   * we don't need to do that.
   */
  body(form) {
    return ""
  },
})

export default withPlugin(Layout, [CreatePostPlugin, CreateConsultantPlugin])
