import React, { useState, useEffect, useContext } from "react"
import { LangContext, Languages } from "../../../provider"

const TRIGGER_CHARCODE = 108 // 'l' small letter L
const Toggle: React.FC = () => {
  const langContext = useContext(LangContext)
  const [isEN, toggleIsEN] = useState(
    langContext.lang === Languages.EN ? true : false
  )

  const [charList, setCharList] = useState<number[]>([])
  const handleShortcut = (e: KeyboardEvent) => {
    if (e.keyCode !== TRIGGER_CHARCODE) {
      setCharList([])
    } else {
      setCharList(p => [...p, e.keyCode])
    }
  }
  useEffect(() => {
    if (
      charList.length === 5 &&
      charList.filter(c => c === TRIGGER_CHARCODE).length === 5
    ) {
      toggleIsEN(p => !p)
      setCharList([])
    }
  }, [charList])

  useEffect(() => {
    const prev = window.localStorage.getItem("vp-lang")
    if (prev) {
      prev === Languages.EN ? toggleIsEN(true) : toggleIsEN(false)
    }

    window && window.addEventListener("keypress", handleShortcut)
    return () => {
      setCharList([])
      window && window.removeEventListener("keypress", handleShortcut)
    }
  }, [])

  useEffect(() => {
    if (langContext) {
      if (isEN) {
        langContext.lang !== Languages.EN && langContext.setLang(Languages.EN)
      } else {
        langContext.lang !== Languages.JP && langContext.setLang(Languages.JP)
      }
    }
  }, [isEN])

  const toggle = () => {
    toggleIsEN(p => {
      window.localStorage.setItem("vp-lang", !p ? Languages.EN : Languages.JP)
      return !p
    })
  }

  return (
    <div className="my-auto">
      <button
        className="p-2 font-sans sm:p-1"
        style={{ paddingBottom: "0.4rem" }}
        onClick={toggle}
      >
        <span className={isEN ? "text-gray-900" : "text-gray-500"}>EN</span> |{" "}
        <span className={!isEN ? "text-gray-900" : "text-gray-500"}>JP</span>
      </button>
    </div>
  )
}

export default Toggle
