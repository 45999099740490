import React from "react"
import { vpBlue } from "../../../assets/colors"

interface Props {
  width?: number
}

export const VPIcon: React.FC<Props> = ({ width = 200 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: width }}
      viewBox="0 0 52.57 52.57"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LAYOUT">
          <path
            fill={vpBlue}
            d="M52.57,26.28A26.29,26.29,0,1,1,26.28,0,26.29,26.29,0,0,1,52.57,26.28ZM16.42,18.71a4.52,4.52,0,0,1-.49-1.6c0-.73.73-1.14,3.14-1.3v-.74H6.78v.74c2.17.24,2.82,2,3.72,4l8.57,20.54h.78l2.61-7ZM35,29.14v.75c5.64-.16,11-2.16,11-7.55s-3.94-7.26-11.16-7.26H23.59v.75c3.44.12,3.61.62,3.61,4.31V35.33c0,3.69-.17,4.19-3.61,4.31v.75h13.9v-.75c-4.19-.12-4.4-.62-4.4-4.31v-18c0-.62,0-1,1.7-1,3.4,0,4.94,2.29,4.94,6.35S38.33,28.77,35,29.14Z"
          />
        </g>
      </g>
    </svg>
  )
}
