import React from "react"
import logo from "../../assets/Logo.svg"

export const Logo: React.FC = () => {
  return (
    <div className="w-56 sm:w-300px pb-2 sm:pb-0">
      <img src={logo} />
    </div>
  )
}
