import * as React from "react"
import { motion } from "framer-motion"
import { MenuItem } from "./MenuItem"

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

export const Navigation: React.FC = ({ children }) => (
  <motion.ul
    variants={variants}
    className=" p-4"
    style={{ top: 100, width: 230 }}
  >
    {children &&
      children.map((child, i) => (
        <MenuItem i={i} key={i}>
          {child}
        </MenuItem>
      ))}
  </motion.ul>
)

const itemIds = [0, 1, 2, 3, 4]
