import { Job } from "../components/RecentPositions/types"
import { Languages } from "../provider"

export const pages = {
  top: "/",
  jobSeekers: "/talents",
  hiringManagers: "/hiring-managers",
  aboutUs: "/about",
  workForUs: "/careers",
  privacy: "/privacy-policy",
}

export const makeNotification: (props: {
  title: string
  description: string
  date?: string
}) => Job & { type: "notifications" } = ({
  title,
  description,
  date = "",
}) => ({
  node: {
    frontmatter: {
      date,
      title,
      description,
      location: "",
      filterType: "",
      lang: "",
    },
    fields: {
      slug: "",
    },
  },
  type: "notifications" as const,
})

export const makeNewsRelease: (props: {
  title: string
  description: string
  date?: string
}) => Job & { type: "announcements" } = ({
  title,
  description,
  date = "",
}) => ({
  node: {
    frontmatter: {
      date,
      title,
      description,
      location: "",
      filterType: "",
      lang: "",
    },
    fields: {
      slug: "/en.pdf",
      slugJp: "/jp.pdf",
    },
  },
  type: "announcements" as const,
})

// linnked and email currently used in default values for consultants
export const externalLinks = {
  linkedIn: "https://www.linkedin.com/company/vantagepoint-k-k-/",
  facebook: "https://www.facebook.com/vantagepointcareers/",
  instagram: "https://www.instagram.com/vantagepoint.careers/",
  locationMap: {
    tokyo: "https://goo.gl/maps/tH3qQZTv3z44Q8TG8",
    charlotte: "https://maps.app.goo.gl/oE5gZMYpE11wNEbn9",
    // hongkong: "https://goo.gl/maps/cYkUgSbepfwKCK5Z8",
  },
  email: "info@vpointcareers.com",
  // vpDigital: "https://vpdigital.vpointcareers.com",
}

export const WIDTH_BREAKPOINT = 600

export const filterTypeOptions = [
  "Consulting Firms",
  "Financial Institutions",
  "Corporate Brands",
]

export const seniority = [
  "Executive",
  "Vice President",
  "Director",
  "Mid-Senior level",
  "Associate",
  "Entry level",
  "Internship",
  "Not Applicable",
]

export interface AwardProps {
  year: number
  sector: string
  title: string
  awardOrganization: string
}

export const awardsList: { [key in Languages]: AwardProps[] } = {
  [Languages.EN]: [
    {
      year: 2015,
      sector: "Consulting Sector",
      title: "MVP Award Japan",
      awardOrganization: "BizReach",
    },
    {
      year: 2015,
      sector: "",
      title: "Outstanding Recruiter Award",
      awardOrganization: "Recruitment International",
    },
    {
      year: 2016,
      sector: "Consulting Sector",
      title: "MVP Award Japan",
      awardOrganization: "BizReach",
    },
    {
      year: 2017,
      sector: "Consulting Sector",
      title: "MVP Award Japan",
      awardOrganization: "BizReach",
    },
    {
      year: 2018,
      sector: "",
      title: "Outstanding Performance Award",
      awardOrganization: "BizReach",
    },
    {
      year: 2018,
      sector: "",
      title: "Most Placement Ranking 3rd place",
      awardOrganization: "Career Carver",
    },
    {
      year: 2019,
      sector: "",
      title: "Most Placement Ranking 4th place",
      awardOrganization: "Career Carver",
    },
    {
      year: 2019,
      sector: "",
      title: "Scouting campaign",
      awardOrganization: "Career Carver",
    },
  ].reverse(),
  [Languages.JP]: [
    {
      year: 2015,
      sector: "",
      title: "ヘッドハンター大賞コンサルティング部門MVP受賞",
      awardOrganization: "BizReach主催",
    },
    {
      year: 2015,
      sector: "",
      title: "Outstanding Recruiter Award受賞",
      awardOrganization: "Recruit International主催",
    },
    {
      year: 2016,
      sector: "",
      title: "ヘッドハンター大賞コンサルティング部門MVP受賞",
      awardOrganization: "BizReach主催",
    },
    {
      year: 2017,
      sector: "",
      title: "ヘッドハンター大賞コンサルティング部門MVP受賞",
      awardOrganization: "BizReach主催",
    },
    {
      year: 2018,
      sector: "",
      title: "Outstanding Performance Award受賞",
      awardOrganization: "BizReach主催",
    },
    {
      year: 2018,
      sector: "",
      title: "転職決定人数部門3位",
      awardOrganization: "Career Carver主催",
    },
    {
      year: 2019,
      sector: "",
      title: "転職決定人数部門4位",
      awardOrganization: "Career Carver主催",
    },
    {
      year: 2019,
      sector: "",
      title: "スカウティングキャンペーン",
      awardOrganization: "Career Carver主催",
    },
  ].reverse(),
}

export const jobFunctions = [
  "Accounting / Auditing",
  "Administrative",
  "Advertising",
  "Analyst",
  "Art / Creative",
  "Business Development",
  "Consulting",
  "Customer Service",
  "Design",
  "Distribution",
  "Education",
  "Engineering",
  "Finance",
  "General Business",
  "Health Care Provider",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Management",
  "Manufacturing",
  "Marketing",
  "Other",
  "Product Management",
  "Production",
  "Project Management",
  "Public Relations",
  "Purchasing",
  "Quality Assurance",
  "Research",
  "Sales",
  "Science",
  "Strategy / Planning",
  "Supply Chain",
  "Training",
  "Writing / Editing",
]

export type JobFunctions =
  | "Accounting / Auditing"
  | "Administrative"
  | "Advertising"
  | "Analyst"
  | "Art / Creative"
  | "Business Development"
  | "Consulting"
  | "Customer Service"
  | "Design"
  | "Distribution"
  | "Education"
  | "Engineering"
  | "Finance"
  | "General Business"
  | "Health Care Provider"
  | "Human Resources"
  | "Information Technology"
  | "Legal"
  | "Management"
  | "Manufacturing"
  | "Marketing"
  | "Other"
  | "Product Management"
  | "Production"
  | "Project Management"
  | "Public Relations"
  | "Purchasing"
  | "Quality Assurance"
  | "Research"
  | "Sales"
  | "Science"
  | "Strategy / Planning"
  | "Supply Chain"
  | "Training"
  | "Writing / Editing"

export const industry = [
  "Accounting",
  "Administration",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts and Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies and Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-Learning",
  "Electrical/Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fund-Raising",
  "Furniture",
  "Gambling & Casinos",
  "Glass",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import and Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology and Services",
  "Insurance",
  "International Affairs",
  "International Trade and Development",
  "Internet",
  "Investment Banking",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure",
  "Libraries",
  "Logistics and Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Market Research",
  "Marketing and Advertising",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Devices",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures and Film",
  "Museums and Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Media",
  "Other",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging and Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary Education",
  "Printing",
  "Professional Training & Coaching",
  "Program Development",
  "Public Policy",
  "Public Relations and Communications",
  "Public Safety",
  "Publishing",
  "Radio",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational Facilities and Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security and Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing and Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation and Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital & Private Equity",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine and Spirits",
  "Wireless",
  "Writing and Editing",
]

export type Industry =
  | "Accounting"
  | "Administration"
  | "Airlines/Aviation"
  | "Alternative Dispute Resolution"
  | "Alternative Medicine"
  | "Animation"
  | "Apparel & Fashion"
  | "Architecture & Planning"
  | "Arts and Crafts"
  | "Automotive"
  | "Aviation & Aerospace"
  | "Banking"
  | "Biotechnology"
  | "Broadcast Media"
  | "Building Materials"
  | "Business Supplies and Equipment"
  | "Capital Markets"
  | "Chemicals"
  | "Civic & Social Organization"
  | "Civil Engineering"
  | "Commercial Real Estate"
  | "Computer & Network Security"
  | "Computer Games"
  | "Computer Hardware"
  | "Computer Networking"
  | "Computer Software"
  | "Construction"
  | "Consumer Electronics"
  | "Consumer Goods"
  | "Consumer Services"
  | "Cosmetics"
  | "Dairy"
  | "Defense & Space"
  | "Design"
  | "Education Management"
  | "E-Learning"
  | "Electrical/Electronic Manufacturing"
  | "Entertainment"
  | "Environmental Services"
  | "Events Services"
  | "Executive Office"
  | "Facilities Services"
  | "Farming"
  | "Financial Services"
  | "Fine Art"
  | "Fishery"
  | "Food & Beverages"
  | "Food Production"
  | "Fund-Raising"
  | "Furniture"
  | "Gambling & Casinos"
  | "Glass"
  | "Government Administration"
  | "Government Relations"
  | "Graphic Design"
  | "Health"
  | "Higher Education"
  | "Hospital & Health Care"
  | "Hospitality"
  | "Human Resources"
  | "Import and Export"
  | "Individual & Family Services"
  | "Industrial Automation"
  | "Information Services"
  | "Information Technology and Services"
  | "Insurance"
  | "International Affairs"
  | "International Trade and Development"
  | "Internet"
  | "Investment Banking"
  | "Investment Management"
  | "Judiciary"
  | "Law Enforcement"
  | "Law Practice"
  | "Legal Services"
  | "Legislative Office"
  | "Leisure"
  | "Libraries"
  | "Logistics and Supply Chain"
  | "Luxury Goods & Jewelry"
  | "Machinery"
  | "Management Consulting"
  | "Maritime"
  | "Market Research"
  | "Marketing and Advertising"
  | "Mechanical or Industrial Engineering"
  | "Media Production"
  | "Medical Devices"
  | "Medical Practice"
  | "Mental Health Care"
  | "Military"
  | "Mining & Metals"
  | "Motion Pictures and Film"
  | "Museums and Institutions"
  | "Music"
  | "Nanotechnology"
  | "Newspapers"
  | "Nonprofit Organization Management"
  | "Oil & Energy"
  | "Online Media"
  | "Other"
  | "Outsourcing/Offshoring"
  | "Package/Freight Delivery"
  | "Packaging and Containers"
  | "Paper & Forest Products"
  | "Performing Arts"
  | "Pharmaceuticals"
  | "Philanthropy"
  | "Photography"
  | "Plastics"
  | "Political Organization"
  | "Primary/Secondary Education"
  | "Printing"
  | "Professional Training & Coaching"
  | "Program Development"
  | "Public Policy"
  | "Public Relations and Communications"
  | "Public Safety"
  | "Publishing"
  | "Radio"
  | "Railroad Manufacture"
  | "Ranching"
  | "Real Estate"
  | "Recreational Facilities and Services"
  | "Religious Institutions"
  | "Renewables & Environment"
  | "Research"
  | "Restaurants"
  | "Retail"
  | "Security and Investigations"
  | "Semiconductors"
  | "Shipbuilding"
  | "Sporting Goods"
  | "Sports"
  | "Staffing and Recruiting"
  | "Supermarkets"
  | "Telecommunications"
  | "Textiles"
  | "Think Tanks"
  | "Tobacco"
  | "Translation and Localization"
  | "Transportation/Trucking/Railroad"
  | "Utilities"
  | "Venture Capital & Private Equity"
  | "Veterinary"
  | "Warehousing"
  | "Wholesale"
  | "Wine and Spirits"
  | "Wireless"
  | "Writing and Editing"
