import { useEffect, useState, useRef } from "react"
import { WIDTH_BREAKPOINT } from "../../constants"

export const useDimensions = (
  initialData = { height: 0, width: 0 },
  debounced = true
) => {
  const resizeRef = useRef()
  const [height, setHeight] = useState(initialData.height || 0)
  const [width, setWidth] = useState(initialData.width || 0)
  useEffect(() => {
    window && window.addEventListener("resize", handleResize, false)
    handleResize()

    return () => window && window.removeEventListener("resize", handleResize)
  }, [])

  const handleResize = () => {
    if (debounced && resizeRef.current) {
      clearTimeout(resizeRef.current)
      resizeRef.current = setTimeout(() => {
        setWidth(window && window.innerWidth)
        setHeight(window && window.innerHeight)
      }, 250)
    } else {
      requestAnimationFrame(() => {
        setWidth(window && window.innerWidth)
        setHeight(window && window.innerHeight)
      })
    }
  }

  return { height: height, width: width }
}

export const useIsMobile = () => {
  const { width } = useDimensions({ width: 800, height: 600 })

  return width < WIDTH_BREAKPOINT
}

// Naive implementation - in reality would want to attach
// a window or resize listener. Also use state/layoutEffect instead of ref/effect
// if this is important to know on initial client render.
// It would be safer to  return null for unmeasured states.
export const useSimpleDimensions = ref => {
  const dimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth
    dimensions.current.height = ref.current.offsetHeight
  }, [])

  return dimensions.current
}
