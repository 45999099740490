import { Link } from "gatsby"
import React, { useState, useEffect, useRef } from "react"
import { Logo } from "./Logo"

import LanguageToggle from "./LanguageToggle"
import { useDimensions, useSimpleDimensions } from "../hooks/useDimensions"
import { useCycle, motion } from "framer-motion"
import { MenuToggle } from "./Menu"
import { Navigation } from "./Menu/Navigation"

interface TabProps {
  text: string
  url?: string
  selected: boolean
  classes?: string
}

const Tab: React.FC<TabProps> = ({ text, url = "/", selected, classes }) => {
  return (
    <div className={`group my-auto mb-0 ml-8 ${classes}`}>
      <h2
        className={`text-gray-900 font-serif leading-none group-hover:text-vp-blue ${selected &&
          "text-vp-blue"}`}
        style={{ fontSize: 28 }}
      >
        <Link to={`${url}`}>{text}</Link>
      </h2>
      <hr
        className={`border-b-2 border-blue-600 opacity-0 group-hover:opacity-100 ${selected &&
          "opacity-100"}`}
      />
    </div>
  )
}

interface Menu {
  path: string
}

const MobileMenu: React.FC<Menu> = ({ path }) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height, width } = useSimpleDimensions(containerRef)
  const dimensions = useDimensions()

  const sidebar = {
    open: (height = 1000) => ({
      width: Math.max(dimensions.width / 2, 300),
      transition: {
        ease: "easeOut",
      },
    }),
    closed: {
      width: 0,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  }

  const toggle = () => toggleOpen()

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <div className="pb-8">
        <MenuToggle toggle={toggle} />
      </div>
      <motion.div
        className="fixed top-0 bottom-0 right-0 z-50 h-screen bg-gray-100"
        variants={sidebar}
      >
        <div className="flex flex-col">
          <div className="flex justify-between p-8">
            <div className="ml-2">
              <LanguageToggle />
            </div>
            <MenuToggle toggle={toggle} />
          </div>

          <Navigation>
            <Tab classes="mb-2" text="Home" url="/" selected={path === "/"} />
            <Tab
              classes="mb-2"
              text="Job Seekers"
              url="/talents"
              selected={path === "/talents"}
            />
            <Tab
              classes="mb-2"
              text="Hiring Managers"
              url="/hiring-managers"
              selected={path === "/hiring-managers"}
            />
            <Tab
              classes="mb-2"
              text="About us"
              url="/about"
              selected={path === "/about"}
            />
            <Tab
              classes="mb-2"
              text="Work for us"
              url="/careers"
              selected={path === "/careers"}
            />
          </Navigation>
        </div>
      </motion.div>
    </motion.nav>
  )
}

const Menu: React.FC<Menu> = ({ path }) => {
  const dimensions = useDimensions()

  const desktop = (
    <div className="flex" style={{ paddingBottom: 27 }}>
      <Tab text="Job Seekers" url="/talents" selected={path === "/talents"} />
      <Tab
        text="Hiring Managers"
        url="/hiring-managers"
        selected={path === "/hiring-managers"}
      />
      <Tab text="About us" url="/about" selected={path === "/about"} />
      <Tab text="Work for us" url="/careers" selected={path === "/careers"} />
      <div className="ml-8">
        <LanguageToggle />
      </div>
    </div>
  )

  return dimensions.width > 1250 ? desktop : <MobileMenu path={path} />
}

interface Props {
  siteTitle: string
  path: Location["pathname"]
}

const Header: React.FC<Props> = ({ siteTitle = "", path }) => (
  <header className="flex items-end justify-between h-32 px-6 m-0 bg-white xs:px-10 sm:px-16 sm:h-150px">
    <div style={{ paddingBottom: 27 }}>
      <Link to={`/`}>
        <Logo />
      </Link>
    </div>
    <Menu path={path} />
  </header>
)

export default Header
